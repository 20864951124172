import React, { useState } from "react";
import BoardModal from "./BoardModal";
import { logEvent } from "firebase/analytics";
import { analytics } from "../fire";
import { useParams } from "react-router-dom";

import styles from "./RulesModal.module.scss";
import Rules from "./Rules";

function RulesModal({ game, phase, events, closeModal }) {
  const { gameString } = useParams();

  const [age, setAge] = useState();

  return (
    <BoardModal closeModal={() => closeModal}>
      <Rules title={game.title} description={game.description} />

      {phase === "startGame" && (
        <form
          className={styles.form}
          onSubmit={() => {
            closeModal();
            events.endPhase();
            logEvent(analytics, "gameStarted", { game: gameString, age });
          }}
        >
          <h2>Before you start...</h2>
          <div className={styles.fieldWrapper}>
            <label className={styles.label} htmlFor="ageInput">
              How old are you?
            </label>
            <input
              className={styles.input}
              onChange={(e) => setAge(e.target.value)}
              value={age}
              name="ageInput"
              type="text"
              pattern="[0-9]{1,2}"
              title="This field is required"
              required
            />
          </div>
          <button className={styles.button}>Start the game</button>
        </form>
      )}

      {phase !== "startGame" && (
        <button
          className={styles.button}
          onClick={() => {
            closeModal();
          }}
        >
          Back to the game!
        </button>
      )}
    </BoardModal>
  );
}

export default RulesModal;
