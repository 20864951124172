import React from "react";
import styles from "./PlayerFeedback.module.scss";
import Red from "../assets/img/red.png";
import Amber from "../assets/img/amber.png";
import Green from "../assets/img/green.png";

const PlayerFeedback = ({ value, onChange, onSubmit }) => {
  return (
    <div>
      <form onSubmit={onSubmit}>
        <h3 htmlFor="gameRating">Did you enjoy playing Food-Flips?</h3>
        <p>Tap on a face to tell us how much you enjoyed the game.</p>
        <ol className={styles.ratings}>
          <li>
            <button
              onClick={() => onChange(1)}
              className={value === 1 ? styles.userRating : styles.rating}
              type="button"
            >
              <img alt="An unhappy looking face" src={Red} />
            </button>
          </li>
          <li>
            <button
              onClick={() => onChange(2)}
              className={value === 2 ? styles.userRating : styles.rating}
              type="button"
            >
              <img alt="A face with a blank expression" src={Amber} />
            </button>
          </li>
          <li>
            <button
              onClick={() => onChange(3)}
              className={value === 3 ? styles.userRating : styles.rating}
              type="button"
            >
              <img alt="A smiling face" src={Green} />
            </button>
          </li>
        </ol>
        <button
          disabled={value === 0}
          className={
            value === 1
              ? styles.button1
              : value === 2
              ? styles.button2
              : styles.button
          }
        >
          Send your feedback
        </button>
      </form>
    </div>
  );
};

export default PlayerFeedback;
