import React from "react";
import nutrientLevel from "../library/nutrientLevel";
import levelToColor from "../library/levelToColor";

const TrafficLight = ({
  nutrient,
  value,
  displayValue,
  className,
  action,
  dataHidden,
  colorsHidden,
  x,
  y,
  width,
  height,
}) => {
  // Energy is always a bit different
  const isEnergy = nutrient.name === "energy" ? true : false;
  const tlLevel = isEnergy ? value : displayValue;

  const level = nutrientLevel({
    nutrient: nutrient.name,
    value: value,
  });
  const color = isEnergy
    ? "#fff"
    : colorsHidden
    ? "silver"
    : levelToColor(level);

  return (
    <svg
      className={className}
      viewBox="0 0 57 104"
      x={x}
      y={y}
      width={width}
      height={height}
    >
      <path
        d="M28.5,1.5C8.25,1.5,1.5,15,1.5,15V89.25s6.75,13.5,27,13.5,27-13.5,27-13.5V15S48.75,1.5,28.5,1.5Z"
        fill={color}
        stroke="#000"
        strokeWidth="3"
      />

      <g textAnchor="middle" fontWeight="700">
        <text x="28.5" y="20" fontSize="10" letterSpacing="-.3">
          {nutrient.trafficLightLabel}
        </text>
        <rect x="8.5" y="48.75" width="40.5" height="16" rx="2" fill="#fff" />

        {!dataHidden && (
          <g>
            <text
              x="28.5"
              y="40"
              fontSize={isEnergy ? 10 : 13}
              letterSpacing="-.5"
            >
              {`${tlLevel}${nutrient.unit}`}
            </text>

            <text x="28.5" y="60" fontSize="10.5">
              {level}
            </text>
          </g>
        )}
      </g>

      <rect
        style={{
          cursor:
            !isEnergy && typeof action === "function" ? "pointer" : "default",
        }}
        fill="transparent"
        width="57"
        height="104"
        onClick={isEnergy ? null : action}
      />
    </svg>
  );
};

export default TrafficLight;
