import React from "react";
import nutrients from "../library/nutrients-object";
import nutrientLevel from "../library/nutrientLevel";
import levelToColor from "../library/levelToColor";
import TrafficLight from "./TrafficLight";

const Podium = ({ card, nutrient, className }) => {
  const level = nutrientLevel({ nutrient, value: card[nutrient] });

  const color = levelToColor(level);
  const angleMin = -6;
  const angleMax = 6;
  const angle = Math.floor(
    Math.random() * (angleMax - angleMin + 1) + angleMin
  );

  return (
    <div>
      <svg
        preserveAspectRatio="xMidYMid"
        className={className}
        viewBox="0 0 100 100"
      >
        <circle cx="50" cy="50" r="32" fill={color} />
        <circle
          cx="50"
          cy="50"
          r="35"
          fill="transparent"
          stroke="black"
          strokeWidth="1.5"
        />
        <image
          width="100"
          height="100"
          href={card.image}
          transform={`rotate(${angle}, 50 , 50)`}
        />
        <TrafficLight
          x="70"
          y="40"
          width="30"
          height="60"
          nutrient={nutrients[nutrient]}
          value={card[nutrient]}
          displayValue={card[`${nutrient}Label`]}
        />
      </svg>
    </div>
  );
};

export default Podium;
