import React from "react";
import styles from "./ScoreBar.module.scss";

const ScoreBar = ({ one = 10, two = 10, table = 10, className }) => {
  const total = one + two + table;
  const unit = 100 / total;
  const onePercent = one * unit;
  const twoPercent = two * unit;
  const tablePercent = table * unit;
  const barHeight = 3;
  const textHeight = barHeight * 0.8;
  return (
    <div className={className}>
      <svg style={{ display: "block" }} viewBox={`0 0 100 ${barHeight * 2}`}>
        <rect y={barHeight} height={barHeight} width={100} fill="#f15929" />
        <rect
          className={styles.bar}
          x="0"
          y={barHeight}
          height={barHeight}
          width={onePercent}
          fill="#7bc45f"
        />
        <rect
          className={styles.bar}
          y={barHeight}
          height={barHeight}
          width={tablePercent}
          x={onePercent}
          fill="#fbb03f"
        />
        <rect
          className={styles.bar}
          x={onePercent + tablePercent}
          y={barHeight}
          height={barHeight}
          width={twoPercent}
          fill="#f15929"
        />
        <g fontSize={textHeight}>
          <text y={textHeight}>{one}</text>
          <text y={textHeight} x={100} textAnchor="end">
            {two}
          </text>
          {table && (
            <text
              y={textHeight}
              x={onePercent + tablePercent / 2}
              textAnchor="middle"
            >
              {table}
            </text>
          )}
        </g>
      </svg>
    </div>
  );
};

export default ScoreBar;
