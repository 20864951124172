const nutrientLevel = ({ nutrient, value }) => {
  let level = "low";
  if (value !== undefined) {
    if (nutrient === "fat") {
      if (value > 17.5) {
        level = "high";
      } else if (value > 3) {
        level = "med";
      } else {
        level = "low";
      }
      return level;
    } else if (nutrient === "saturates") {
      if (value > 5) {
        level = "high";
      } else if (value > 1.5) {
        level = "med";
      } else {
        level = "low";
      }
      return level;
    } else if (nutrient === "sugar") {
      if (value > 22.5) {
        level = "high";
      } else if (value > 5) {
        level = "med";
      } else {
        level = "low";
      }
      return level;
    } else if (nutrient === "salt") {
      if (value > 1.5) {
        level = "high";
      } else if (value > 0.3) {
        level = "med";
      } else {
        level = "low";
      }
      return level;
    }
  }
};

export default nutrientLevel;
