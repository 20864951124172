const levelToScore = (level) => {
  let score = 0;
  if (level === "high") {
    score = 3;
  } else if (level === "med") {
    score = 2;
  } else {
    score = 1;
  }
  return score;
};

export default levelToScore;
