import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Deck from "./Pages/Deck";
import GrownUps from "./Pages/GrownUps";

import Home from "./Pages/Home";
import Play from "./Play/Play";

import styles from "./App.module.scss";

const App = () => {
  return (
    <Router>
      <div className={styles.wrapper}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Food Flips</title>
          <meta
            name="description"
            content="Food Flips is a fun game designed to help KS1 &amp; 2 children to understand food labelling"
          />
          <link rel="canonical" href="https://foodflips.fun" />
        </Helmet>
        <Header />
        <Switch>
          <Route path="/" exact>
            <Home />
            <Footer />
          </Route>
          <Route path="/grownups" exact>
            <GrownUps />
            <Footer />
          </Route>
          <Route path="/deck" exact>
            <Deck />
            <Footer />
          </Route>
          <Route path="/play/:gameString" component={Play} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
