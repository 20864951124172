import React from "react";
import styles from "./FeedbackModule.module.scss";
const FeedbackModule = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h2>We want your help in making Food Flips the best game we can</h2>
        <p>
          We would love to know what you think about the game, maybe draw us a
          picture of your favourite character, ask a grown up to help you and
          send it to <a href="mailto:sugar@qmul.ac.uk">sugar@qmul.ac.uk</a>.
        </p>
        Grown ups can{" "}
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeLyn8j1fCE9Ggay-y_aK3tUvCAiHJ1vrUE1pITKvwYk2QvdA/viewform">
          give feedback by filling in this survey
        </a>
        .
      </div>
    </div>
  );
};

export default FeedbackModule;
