const levelToColor = (level) => {
  const red = "#f15929";
  const orange = "#fbb03f";
  const green = "#7bc45f";

  let color = "";
  if (level === "high") {
    color = red;
  } else if (level === "med") {
    color = orange;
  } else {
    color = green;
  }
  return color;
};

export default levelToColor;
