import nutrients from "./nutrients-object";
import nutrientLevel from "./nutrientLevel";
import levelToLabel from "./levelToLabel";

export const labelPhrasing = ({ card, joiner }) => {
  const prefix = card.prefix || "";
  const suffix = joiner ? (card.singular ? "is" : "are") : "";

  return `${prefix} ${card.label} ${suffix}`;
};

export const titlePhrasing = (playerOne, playerTwo, nutrient, winLoseDraw) => {
  const level = levelToLabel(
    nutrientLevel({
      nutrient: nutrient,
      value: playerOne[nutrient],
    })
  );
  const nutrientLabel = nutrients[nutrient].label;
  let string = "";

  const cardOneWinLosePhrasing = labelPhrasing({
    card: playerOne,
    joiner: true,
  });
  const cardOneDrawPhrasing = labelPhrasing({
    card: playerOne,
  });

  const cardTwoPhrasing = labelPhrasing({
    card: playerTwo,
  });
  switch (winLoseDraw) {
    case "win":
      string = `Well done! ${cardOneWinLosePhrasing} lower in ${nutrientLabel} than ${cardTwoPhrasing}`;
      break;
    case "lose":
      string = `Bad luck! ${cardOneWinLosePhrasing} higher in ${nutrientLabel} than ${cardTwoPhrasing}`;
      break;
    default:
      string = `It’s a draw! ${cardOneDrawPhrasing} and ${cardTwoPhrasing} both contain a ${level} amount of ${nutrientLabel}`;
      break;
  }

  return string;
};

export const nutrientLinePhrasing = (playerOne, playerTwo, nutrient) => {
  const nutrientLabelKey = `${nutrient}Label`;
  const nutrientName = nutrients[nutrient].label;
  const playerOneString = `${playerOne.label} contains ${playerOne[nutrientLabelKey]}g of ${nutrientName} per 100g.`;
  const playerTwoString = `${playerTwo.label} contains ${playerTwo[nutrientLabelKey]}g of ${nutrientName} per 100g.`;
  const string = `${playerOneString}<br/>${playerTwoString}`;
  return string;
};
