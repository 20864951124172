import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import styles from "./Page.module.scss";
const GrownUps = () => {
  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>About Food Flips</title>
        <meta name="description" content="Food Flips was created by Action " />
      </Helmet>
      <div className={styles.content}>
        <h1>
          Food Flips is a project by Nutritionists at Queen Mary University of
          London
        </h1>
        <p className={styles.intro}>
          We are Nutritionists based at Queen Mary University of London, and we
          were awarded a grant to develop an interactive online version of{" "}
          <a href="http://www.actiononsugar.org/resources/food-flips-game/">
            a game we created during the first lockdown in 2020
          </a>
          .
        </p>
        <img
          className={styles.image}
          alt=""
          src="https://firebasestorage.googleapis.com/v0/b/food-flips.appspot.com/o/hero-image.jpg?alt=media&token=83a18daf-1e39-4235-b175-c650cfed849e"
        />
        <p>
          This new interactive online learning tool helps children to recognise
          and understand food and drink labels, whilst practising maths and
          reading, in a light and fun way. There will also be fun facts
          throughout the game.
        </p>
        <p>
          The game does not call any foods ‘bad’ or ‘good’, nor does it talk or
          teach the child about calories. The game simply aims to introduce the
          traffic light label so players become aware that green is low, amber
          is medium and red is high when they see them on food items.
        </p>
        <p>
          Playing the game will also naturally teach, through observation, the
          types of foods and drink that are high or low in certain nutrients.
          When the game starts, the player will be asked to enter their age – no
          person identifiable data is asked for or should be entered. After
          this, the game can be played as many times as you like. Please rate
          the game using the smiley faces at the end of the game.
        </p>

        <h2>Tell us what you think</h2>
        <img
          className={styles.image}
          alt=""
          src="https://firebasestorage.googleapis.com/v0/b/food-flips.appspot.com/o/ratings.jpg?alt=media&token=6fde5a7f-ea14-4a73-92fd-31255cdf5c27"
        />
        <p className={styles.intro}>
          Although the game is designed with children aged 5-12 years in mind,
          we value your feedback! Whatever your age :) Help provide us with
          feedback by filling out our short survey
        </p>
        <div className={styles.ctaWrapper}>
          <a
            className={styles.button}
            href="https://docs.google.com/forms/d/e/1FAIpQLSeLyn8j1fCE9Ggay-y_aK3tUvCAiHJ1vrUE1pITKvwYk2QvdA/viewform"
          >
            Complete our survey
          </a>
        </div>
        <p>
          We also would love feedback from children, such as drawings of their
          favourite cards, which can be sent to{" "}
          <a href="mailto:sugar@qmul.ac.uk">sugar@qmul.ac.uk</a>. You can{" "}
          <Link to="/deck">
            view all of the cards in the Food-Flips deck here
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default GrownUps;
