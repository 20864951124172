import React from "react";
import { titlePhrasing, nutrientLinePhrasing } from "../library/phrasing";
import Podium from "./Podium";
import styles from "./WinMessage.module.scss";

function WinMessage({
  game,
  players,
  nutrient,
  isDraw,
  playerWins,
  closeMessage,
}) {
  const playerOne = players.one.card;
  const playerTwo = players.two.card;

  const messages = {
    win: {
      title: titlePhrasing(playerOne, playerTwo, nutrient, "win"),
      nutrientLine: nutrientLinePhrasing(playerOne, playerTwo, nutrient),
      fact: playerOne.fact || playerTwo.fact,
    },
    lose: {
      title: titlePhrasing(playerOne, playerTwo, nutrient, "lose"),
      nutrientLine: nutrientLinePhrasing(playerOne, playerTwo, nutrient),
      fact: playerTwo.fact || playerOne.fact,
    },
    draw: {
      title: titlePhrasing(playerOne, playerTwo, nutrient, "draw"),
      nutrientLine: nutrientLinePhrasing(playerOne, playerTwo, nutrient),
      fact: playerOne.fact || playerTwo.fact,
    },
  };

  const strings = isDraw
    ? messages.draw
    : playerWins
    ? messages.win
    : messages.lose;

  return (
    <div className={styles.wrapper}>
      <div>
        <h1 className={styles.title}>{strings.title}</h1>
        <div
          className={styles.details}
          dangerouslySetInnerHTML={{ __html: strings.nutrientLine }}
        />
      </div>
      <div className={styles.players}>
        <Podium
          className={styles.playerOne}
          card={playerOne}
          nutrient={nutrient}
        />
        <Podium
          className={styles.playerTwo}
          card={playerTwo}
          nutrient={nutrient}
        />
      </div>

      {strings.fact !== undefined && (
        <div className={styles.didYouKnow}>
          <h2 className={styles.didYouKnowTitle}>Did you know?</h2>
          <div className={styles.didYouKnowContent}>{strings.fact}</div>
        </div>
      )}

      <div className={styles.buttonWrapper}>
        <button className={styles.button} onClick={() => closeMessage()}>
          Next Card
        </button>
      </div>
    </div>
  );
}

export default WinMessage;
