import React from "react";
import { Link } from "react-router-dom";
import FeedbackModule from "../components/FeedbackModule";
import GamesNav from "../components/GamesNav";
import styles from "./Home.module.scss";

const Home = () => {
  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <img
            alt=""
            className={styles.image}
            src="https://firebasestorage.googleapis.com/v0/b/food-flips.appspot.com/o/hero-image.jpg?alt=media&token=83a18daf-1e39-4235-b175-c650cfed849e"
          />
          <div className={styles.text}>
            <h1 className={styles.title}>WELCOME TO FOOD FLIPS</h1>
            <p className={styles.intro}>
              Have you ever looked at a tasty food or drink and seen green,
              orange and red colours like a traffic light on the packet? Well,
              this game will help you understand exactly what they mean.
            </p>
            <p>
              Do you think you have what it takes to beat the computer?{" "}
              <Link to="/grownups">Find out more about the project</Link>
            </p>
          </div>
        </div>
      </div>
      <GamesNav />
      <FeedbackModule />
    </div>
  );
};

export default Home;
