import cards from "../library/cards.js";
import levelToScore from "../library/levelToScore.js";
import nutrientLevel from "../library/nutrientLevel.js";
import sound from "../util/sound";

export function Game({ rules }) {
  return {
    setup: (ctx) => {
      const G = {
        table: [],
        players: {
          one: { card: {}, hand: [] },
          two: { card: {}, hand: [] },
        },
        turnWinner: undefined,
        nutrientSelected: undefined,
        rules,
      };

      return G;
    },
    phases: {
      startGame: {
        next: "play",
        onEnd: (G, ctx) => dealHands(G, ctx),
        moves: { dealHands },
        start: true,
      },

      play: {
        moves: { selectNutrient, newCards, resetTurn, moveCards },
      },
    },

    turn: {
      activePlayers: { all: "dealTurn" },
      stages: {
        dealTurn: { next: "playCard" },
        playCard: {
          next: "announceTurnWinner",
        },
        announceTurnWinner: { next: "completeTurn" },
        completeTurn: { next: "dealTurn" },
      },
    },
    moves: {
      dealHands,
      newCards,
      resetTurn,
      moveCards,
      selectNutrient,
    },
    endIf: (G, ctx) => {
      if (ctx.phase === "play") {
        if (!G.players.two.card) {
          return {
            isWinner: true,
            winMessage: "Well done! You won the game!!!",
          };
        }
        if (!G.players.one.card) {
          return { isWinner: false, winMessage: "Better luck next time" };
        }
      }
    },
    onEnd: (G) => {},
  };

  function dealHands(G, ctx) {
    const deck = ctx.random.Shuffle(cards);
    const { players } = G;
    sound.play("deal");
    deck.forEach((card, i) => {
      if (i % 2) {
        players.one.hand.push(card);
      } else {
        players.two.hand.push(card);
      }
    });
  }

  function drawCards(G) {
    if (G) {
      G.players.one.card = G.players.one.hand.shift();
      G.players.two.card = G.players.two.hand.shift();
    }
    sound.play("turn");
  }
  function newCards(G, ctx) {
    drawCards(G);
  }

  function resetTurn(G) {
    G.turnWinner = undefined;
    G.nutrientSelected = undefined;
  }

  function moveCards(G, ctx) {
    const {
      players: { one, two },
      table,
      turnWinner,
    } = G;
    // WIN!
    if (turnWinner === "one") {
      one.hand.push(one.card, two.card, ...table);
      table.length = 0;
    }
    // LOSE!
    if (turnWinner === "two") {
      two.hand.push(one.card, two.card, ...table);
      table.length = 0;
    }
    // DRAW!
    if (turnWinner === "draw") {
      table.push(one.card, two.card);
    }
  }
  function selectNutrient(G, ctx, nutrient) {
    G.nutrientSelected = nutrient;
    const {
      players: { one, two },
    } = G;

    const playerOneScore =
      rules.compare === "colors"
        ? levelToScore(nutrientLevel({ nutrient, value: one.card[nutrient] }))
        : one.card[nutrient];

    const playerTwoScore =
      rules.compare === "colors"
        ? levelToScore(nutrientLevel({ nutrient, value: two.card[nutrient] }))
        : two.card[nutrient];

    // WIN!
    if (playerOneScore < playerTwoScore) {
      sound.play("win");
      G.turnWinner = "one";
    }
    // LOSE!
    if (playerOneScore > playerTwoScore) {
      sound.play("lose");
      G.turnWinner = "two";
    }
    // DRAW!
    if (playerOneScore === playerTwoScore) {
      sound.play("draw");
      G.turnWinner = "draw";
    }
  }
}
