import React from "react";
import cardBack from "../assets/img/card-back.png";
import styles from "./Card.module.scss";
const Card = ({ className, card, stack, children, cardHidden }) => {
  const { label, image } = card;

  return (
    <svg
      preserveAspectRatio="xMinYMin"
      className={className}
      viewBox="-25 -25 275 400"
    >
      <defs>
        <linearGradient id="myGradient" gradientTransform="rotate(90)">
          <stop offset="-15%" stopColor="#fff" />
          <stop offset="90%" stopColor="#f8e0bd" />
        </linearGradient>

        <filter id="drop-shadow">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3" result="blur" />
          <feoffset in="blur" dx="4" dy="4" result="offsetBlur" />
          <feMerge>
            <feMergeNode in="offsetBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>

        <mask id="card-inner">
          <rect width="215" height="340" fill="white" rx="10" x="5" y="5" />
        </mask>
      </defs>

      <symbol id="card-back" viewBox="0 0 225 350" width="225" height="350">
        <rect
          width="225"
          height="350"
          fill="#fbb03f"
          rx="15"
          stroke="#bc842f"
          strokeWidth="1"
        />
        <image
          mask="url(#card-inner)"
          width="225"
          height="350"
          href={cardBack}
          rx="15"
          style={{ filter: "url(#drop-shadow)" }}
        />
      </symbol>

      {stack &&
        stack.map((card, i) => (
          <g
            key={i}
            transform={`rotate(${Math.sin(i * 2)} 112.5 175) translate(${
              stack.length / 2 - Math.sin(i) * 4
            } ${stack.length - i + 2})`}
          >
            <use xlinkHref="#card-back" />
          </g>
        ))}

      {cardHidden ? (
        <use xlinkHref="#card-back" style={{ filter: "url(#drop-shadow)" }} />
      ) : (
        <g className={styles.card}>
          <rect
            width="225"
            height="350"
            fill="#fbb03f"
            stroke="#bc842f"
            strokeWidth="1"
            rx="15"
            style={{ filter: "url(#drop-shadow)" }}
          />
          <rect width="215" height="340" fill="white" rx="10" x="5" y="5" />
          <rect
            x="15"
            y="15"
            width="195"
            height="195"
            fill="url('#myGradient')"
            rx="5"
          />
          <image x="15" y="15" width="195" height="195" href={image} />
          <text
            x="112.5"
            y="240"
            fontSize="17"
            style={{ fontWeight: "bold" }}
            textAnchor="middle"
          >
            {label}
          </text>
          {children}
        </g>
      )}
    </svg>
  );
};

export default Card;
