import { Howl } from "howler";
import sounds from "../assets/audio/sounds.mp3";

const sound = new Howl({
  src: [sounds],
  sprite: {
    deal: [0, 2000],
    turn: [2000, 750],
    win: [3000, 700],
    lose: [4000, 700],
    draw: [5000, 700],
  },
});

export default sound;
