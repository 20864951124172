import React from "react";
import { Link } from "react-router-dom";

import styles from "./GameTeaser.module.scss";
const GameTeaser = ({ game }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h3 className={styles.title}>{game.title}</h3>
        <div className={styles.description}>{game.description}</div>
      </div>
      <Link className={styles.button} to={`/play/${game.name}`}>
        Play Now!
      </Link>
    </div>
  );
};

export default GameTeaser;
