import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../fire";
import Card from "../components/Card";
import BoardModal from "../components/BoardModal";
import TrafficLights from "../components/TrafficLights";
import TableCards from "../components/TableCards";
import WinMessage from "../components/WinMessage";
import RulesModal from "../components/RulesModal";
import styles from "./Board.module.scss";
import ScoreBar from "../components/ScoreBar";
import PlayerFeedback from "../components/PlayerFeedback";

export const Board = ({ G, ctx, moves, game, events }) => {
  const history = useHistory();
  const [gameRulesVisible, setGameRulesVisible] = useState(true);
  const { gameString } = useParams();
  const { players, table, turnWinner, nutrientSelected } = G;
  const { gameover, phase } = ctx;
  let currentStage = "";
  const [userRating, setUserRating] = useState(0);

  // Destructuring the active players part of ctx to get the current stage
  if (ctx.activePlayers) {
    currentStage = ctx.activePlayers[0];
  }

  useEffect(() => {
    logEvent(analytics, "gameCreated", { game: gameString });

    return () =>
      logEvent(analytics, "gameOver", { game: gameString, turns: ctx.turn });
  });

  // This changes the stage of the turn
  useEffect(() => {
    if (currentStage === "dealTurn") {
      moves.newCards && moves.newCards();
      // This could be a dealing animation and then...
      events.setStage("playCard");
    }
    if (currentStage === "playCard") {
      turnWinner && // This is where the reveal of the opponents card would go
        events.setStage("announceTurnWinner");
    }
    if (currentStage === "announceTurnWinner") {
    }
    if (currentStage === "completeTurn") {
      moves.moveCards();
      moves.resetTurn();
      events.endTurn();
    }
  }, [currentStage, moves, events, turnWinner]);

  return !G ? (
    <div>"Loading..."</div>
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.game}>
        <div className={styles.draw}>
          <div className={styles.draw}>
            <TableCards stack={table} />
            <div className={styles.controls}>
              <button
                className={styles.button}
                onClick={() => setGameRulesVisible(true)}
              >
                Rules
              </button>
            </div>
          </div>
        </div>

        <div className={styles.cardsWrapper}>
          <div className={styles.cards}>
            {Object.keys(players).map(
              (key) =>
                players[key].card &&
                Object.keys(players[key].card).length > 0 && (
                  <Card
                    key={key}
                    className={styles.card}
                    card={players[key].card}
                    stack={players[key].hand}
                    cardHidden={
                      currentStage !== "playCard" ||
                      game.cardOptions[key]?.cardHidden
                    }
                  >
                    <TrafficLights
                      moves={moves}
                      card={players[key].card}
                      cardOptions={game.cardOptions[key]}
                    />
                  </Card>
                )
            )}
          </div>
        </div>

        <ScoreBar
          className={styles.scores}
          one={
            players.one.card
              ? players.one.hand.length + 1
              : players.one.hand.length
          }
          two={
            players.two.card
              ? players.two.hand.length + 1
              : players.two.hand.length
          }
          table={table.length}
        />
      </div>
      {currentStage === "announceTurnWinner" && (
        <BoardModal>
          {turnWinner && nutrientSelected && (
            <WinMessage
              game={game}
              playerWins={turnWinner === "one"}
              isDraw={turnWinner === "draw"}
              nutrient={nutrientSelected}
              players={players}
              closeMessage={() => {
                events.setStage("completeTurn");
              }}
            />
          )}
        </BoardModal>
      )}

      {gameRulesVisible && (
        <RulesModal
          game={game}
          phase={phase}
          events={events}
          styles={styles}
          closeModal={() => setGameRulesVisible(false)}
        />
      )}
      {gameover && (
        <BoardModal>
          <h1>{gameover.winMessage}</h1>
          {logEvent(analytics, "gameFinished", {
            game: gameString,
            turns: ctx.turn,
            won: gameover.isWinner,
          })}

          <PlayerFeedback
            value={userRating}
            onChange={setUserRating}
            onSubmit={(e) => {
              e.preventDefault();
              logEvent(analytics, "gameRated", {
                game: gameString,
                turns: ctx.turn,
                rating: userRating,
                won: gameover.isWinner,
              });
              history.push("/");
            }}
          />
        </BoardModal>
      )}
    </div>
  );
};
