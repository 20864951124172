import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Menu.module.scss";

const Menu = () => {
  return (
    <nav className={styles.wrapper}>
      <ul className={styles.list}>
        <li>
          <NavLink
            activeClassName={styles.current}
            className={styles.link}
            to="/"
            exact
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName={styles.current}
            className={styles.link}
            to="/grownups"
          >
            Grown Ups
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
