import React, { Fragment } from "react";
import Card from "../components/Card";
import TrafficLights from "../components/TrafficLights";
import cards from "../library/cards";
import styles from "./Deck.module.scss";

const Deck = () => {
  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1 className={styles.title}>Get to Know The Food Flips Deck</h1>
          <p className={styles.intro}>
            Here are all of the Food Flips cards to explore.
          </p>
        </div>
        <ul className={styles.cards}>
          {cards.map((card) => (
            <li key={card.key}>
              <Card className={styles.card} card={card}>
                <TrafficLights card={card} />
              </Card>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

export default Deck;
