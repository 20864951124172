import React from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.scss";

import logo from "../assets/img/header-logo.svg";
import Menu from "./Menu";
const Header = () => {
  return (
    <div className={styles.wrapper}>
      <header>
        <Link to="/">
          <img className={styles.logo} src={logo} alt="" />
        </Link>
      </header>
      <Menu />
    </div>
  );
};

export default Header;
