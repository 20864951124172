import React from "react";
import Modal from "react-modal";
import styles from "./BoardModal.module.scss";

Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "transparent";

function BoardModal({ children }) {
  return (
    <Modal className={styles.wrapper} isOpen={true}>
      <div className={styles.message}>{children}</div>
    </Modal>
  );
}

export default BoardModal;
