import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDPBriJ4JOakZl6mq_l-GcgSeAKM5Dm4u0",
  authDomain: "food-flips.firebaseapp.com",
  projectId: "food-flips",
  storageBucket: "food-flips.appspot.com",
  messagingSenderId: "88551483852",
  appId: "1:88551483852:web:0562fc610f3abede1e9569",
  measurementId: "G-41HR621E23",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics();
