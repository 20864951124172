import React from "react";
import styles from "./Rules.module.scss";

const Rules = ({ title, description }) => {
  return (
    <div>
      <h1 className={styles.title}>{title}</h1>
      <p dangerouslySetInnerHTML={{ __html: description }} />

      <div className={styles.howTo}>
        <h2 className={styles.howToTitle}>How to play Food Flips</h2>

        <ol className={styles.steps}>
          <li className={styles.step}>
            <img
              className={styles.stepImage}
              alt=""
              src="https://firebasestorage.googleapis.com/v0/b/food-flips.appspot.com/o/step1.jpg?alt=media&token=9707f248-0969-4192-acef-03325f93b989"
            />
            Your cards are the big pile on the left-hand-side of the screen. You
            can see how many cards you have at the bottom of the screen.
          </li>
          <li className={styles.step}>
            <img
              className={styles.stepImage}
              alt=""
              src="https://firebasestorage.googleapis.com/v0/b/food-flips.appspot.com/o/step2.jpg?alt=media&token=bb403534-0232-4c42-b1fd-76df1c10954a"
            />
            To play a hand, tap the traffic-light on your card that you think is
            lower than the computer’s card.
          </li>
          <li className={styles.step}>
            <img
              className={styles.stepImage}
              alt=""
              src="https://firebasestorage.googleapis.com/v0/b/food-flips.appspot.com/o/step3.jpg?alt=media&token=ab02d4e5-aafe-41b0-bb67-5fdf4d93616b"
            />
            If it's a draw, the cards go into the draw pile at the top of the
            screen and will go to the next player to win a round.
          </li>
        </ol>
        <p>The game carries on until one player has all of the cards.</p>
      </div>
      <p>
        You can check these instructions again by clicking the{" "}
        <strong className={styles.rulesLink}>Rules</strong> button at the top of
        the screen.
      </p>
    </div>
  );
};

export default Rules;
