import images from "./images";

const cards = [
  {
    label: "Baked Beans",
    singular: false,
    key: "bakedBeans",
    energy: 78,
    fat: 0.2,
    saturates: 0.099,
    salt: 0.6,
    sugar: 4.7,
    fatLabel: "0.2",
    saltLabel: "0.6",
    saturatesLabel: "<0.1",
    sugarLabel: "4.7",
    fact: "There are approximately 465 beans in every can of baked beans.",
    image: images.bakedBeans,
  },
  {
    prefix: "a",
    label: "Cheese Burger",
    singular: true,
    key: "cheeseBurger",
    energy: 247,
    fat: 11,
    saturates: 4.8,
    sugar: 5,
    salt: 1.4,
    fatLabel: "11",
    saturatesLabel: "4.8",
    sugarLabel: "5",
    saltLabel: "1.4",
    fact: "There is a burger in America called the ‘Fleurburger 5000’ which costs $5000! (that’s £4060)",
    image: images.cheeseBurger,
  },
  {
    prefix: "a",
    label: "Cereal Bar",
    singular: true,
    key: "cerealBar",
    energy: 456,
    fat: 17.2,
    saturates: 2.4,
    sugar: 28.3,
    salt: 0.8,
    fatLabel: "17.2",
    saturatesLabel: "2.4",
    sugarLabel: "28.3",
    saltLabel: "0.8",
    fact: "Did you know? Cereals have been eaten by humans for around 9000 years!",
    image: images.cerealBar,
  },
  {
    label: "Cheddar Cheese",
    singular: true,
    key: "cheeseCheddar",
    energy: 416,
    fat: 34.9,
    saturates: 21.7,
    sugar: 0.1,
    salt: 1.8,
    fatLabel: "34.9",
    saturatesLabel: "21.7",
    sugarLabel: "0.1",
    saltLabel: "1.8",
    fact: "Wensleydale cheese is Wallace and Gromit’s favourite cheese!",
    image: images.cheeseCheddar,
  },
  {
    label: "Chocolate Cereal",
    singular: true,
    key: "cerealChoc",
    energy: 115,
    fat: 4.4,
    saturates: 2.4,
    sugar: 13.4,
    salt: 0.26,
    fatLabel: "4.4",
    saturatesLabel: "2.4",
    sugarLabel: "13.4",
    saltLabel: "0.26",
    fact: "Chocolate in German is Schokolade",
    image: images.cerealChoc,
  },
  {
    prefix: "a",
    label: "Choc Chip Cookie",
    singular: true,
    key: "cookieChocChip",
    energy: 491,
    fat: 22.1,
    saturates: 11.3,
    fatLabel: "22.1",
    sugar: 34.4,
    salt: 0.55,
    saturatesLabel: "11.3",
    sugarLabel: "34.4",
    saltLabel: "0.55",
    fact: "The world’s biggest chocolate chip cookie weighed over 18,144kg in May 2003 and was 30.7m wide! (That is about as heavy as 3 elephants and as wide as the length of 2 and a half lorries!)",
    image: images.cookieChocChip,
  },
  {
    label: "Egg on Toast",
    singular: true,
    key: "toastEgg",
    energy: 228,
    fat: 10.1,
    saturates: 2.1,
    sugar: 1.6,
    salt: 0.76,
    fatLabel: "10.1",
    saturatesLabel: "2.1",
    sugarLabel: "1.6",
    saltLabel: "0.76",
    fact: "You can tell if an egg is fresh by dropping it (carefully) into water. If it drops to the bottom or hovers in the middle it’s good to eat, but if it floats to the top throw it away as it has gone off!",
    image: images.toastEgg,
  },
  {
    label: "French Fries",
    singular: false,
    key: "frenchFries",
    energy: 243,
    fat: 12,
    saturates: 4.3,
    sugar: 0.49,
    salt: 0.5,
    fatLabel: "12",
    saturatesLabel: "4.3",
    sugarLabel: "<0.5",
    saltLabel: "0.5",
    fact: "French fries were actually created in Belgium, and not France! They even have a french fry museum called Frietmuseum!",
    image: images.frenchFries,
  },
  {
    prefix: "a",
    label: "Ham & Cheese Sandwich",
    singular: true,
    key: "sandwichHamCheese",
    energy: 247,
    fat: 10.8,
    saturates: 4.6,
    sugar: 2.5,
    salt: 1.1,
    fatLabel: "10.8",
    saturatesLabel: "4.6",
    sugarLabel: "2.5",
    saltLabel: "1.1",
    fact: "Next time you fancy a sandwich add some colour to it with salad, veggies or even fruit! Ever tried a banana sandwich?",
    image: images.sandwichHamCheese,
  },
  {
    label: "Hot Chocolate",
    singular: true,
    key: "hotChoc",
    energy: 72,
    fat: 2,
    fatLabel: "2",
    saturates: 1.3,
    saturatesLabel: "1.3",
    sugar: 7.3,
    sugarLabel: "7.3",
    salt: 0.1,
    saltLabel: "0.1",
    fact: "The Mayans created the first ‘hot chocolate’ by drying, grinding and mixing cocoa beans with water and often chillies! The Aztecs called it ‘xocolatl’",
    image: images.hotChoc,
  },
  {
    label: "Jelly Sweets",
    singular: false,
    key: "jellySweets",
    energy: 343,
    fat: 0.489,
    fatLabel: "<0.49",
    saturates: 0.1,
    saturatesLabel: "0.1",
    sugar: 46,
    sugarLabel: "46",
    salt: 0.069,
    saltLabel: "<0.07",
    fact: "The largest gummy sweet was made in Germany in February 2014. It weighed 512kg (as heavy as about 17 labradors!)",
    image: images.jellySweets,
  },
  {
    label: "Juice",
    singular: true,
    key: "juiceOrange",
    energy: 43,
    fat: 0,
    fatLabel: "0",
    saturates: 0,
    saturatesLabel: "0",
    sugar: 8.9,
    sugarLabel: "8.9",
    salt: 0,
    saltLabel: "0",
    fact: "Did you know it takes around 3 large oranges squeezed to make a small glass of juice?",
    image: images.juiceOrange,
  },
  {
    label: "Tomato Ketchup",
    singular: true,
    key: "ketchup",
    energy: 102,
    fat: 0.1,
    fatLabel: "0.1",
    saturates: 0,
    saturatesLabel: "0",
    sugar: 22.8,
    sugarLabel: "22.8",
    salt: 1.8,
    saltLabel: "1.8",
    fact: "Some people use ketchup to clean their pans!",
    image: images.ketchup,
  },
  {
    prefix: "a",
    label: "Marshmallow",
    singular: true,
    key: "marshmallow",
    energy: 328,
    fat: 0.09,
    fatLabel: "<0.1",
    saturates: 0.09,
    saturatesLabel: "<0.1",
    sugar: 62.3,
    sugarLabel: "62.3",
    salt: 0.05,
    saltLabel: "0.05",
    fact: "The Ancient Egyptians were the first to enjoy marshmallows made of mallow sap and honey!",
    image: images.marshmallow,
  },
  {
    label: "Mayonnaise",
    singular: true,
    key: "mayo",
    energy: 721,
    fat: 79,
    fatLabel: "79",
    saturates: 6.2,
    saturatesLabel: "6.2",
    sugar: 1.3,
    sugarLabel: "1.3",
    salt: 1.5,
    saltLabel: "1.5",
    fact: "Mayonnaise is made from eggs!",
    image: images.mayo,
  },
  {
    prefix: "a",
    label: "Pepperoni Pizza",
    singular: true,
    key: "pizzaPepperoni",
    energy: 355,
    fat: 15.1,
    fatLabel: "15.1",
    saturates: 7.1,
    saturatesLabel: "7.1",
    sugar: 5.8,
    sugarLabel: "5.8",
    salt: 1.85,
    saltLabel: "1.85",
    fact: "Modern pizzas were invented in Italy in the 18th century!",
    image: images.pizzaPepperoni,
  },
  {
    label: "Semi-skimmed Milk",
    singular: true,
    key: "milkSemi",
    energy: 50,
    fat: 1.8,
    fatLabel: "1.8",
    saturates: 1.1,
    saturatesLabel: "1.1",
    sugar: 4.8,
    sugarLabel: "4.8",
    salt: 0.1,
    saltLabel: "0.1",
    fact: "Milk is packed full of calcium to help us build strong and healthy bones and teeth",
    image: images.milkSemi,
  },
  {
    prefix: "an",
    label: "Omelette",
    singular: true,
    key: "omlette",
    energy: 119,
    fat: 7.7,
    fatLabel: "7.7",
    saturates: 1.6,
    saturatesLabel: "1.6",
    sugar: 1.8,
    sugarLabel: "1.8",
    salt: 0.26,
    saltLabel: "0.26",
    fact: "A Spanish omelette is called a tortilla.",
    image: images.omlette,
  },
  {
    label: "Ready Salted Crisps",
    singular: false,
    key: "crispsReadySalted",
    energy: 526,
    fat: 31.9,
    fatLabel: "31.9",
    saturates: 2.6,
    saturatesLabel: "2.6",
    sugar: 0.4,
    sugarLabel: "0.4",
    salt: 1.4,
    saltLabel: "1.4",
    fact: "In 2013, the biggest packet of crisps was recorded at 1,141 kg! That’s the same as 45,640 packets of regular crisps!",
    image: images.crispsReadySalted,
  },
  {
    label: "Porridge",
    singular: true,
    key: "porridge",
    energy: 85,
    fat: 2,
    fatLabel: "2",
    saturates: 0.9,
    saturatesLabel: "0.9",
    sugar: 5.5,
    sugarLabel: "5.5",
    salt: 0.09,
    saltLabel: "0.09",
    fact: "Porridge is a tasty breakfast that gives us lots of energy for the day and helps make our bones and muscles strong!",
    image: images.porridge,
  },
  {
    label: "Fizzy Drinks",
    singular: false,
    key: "fizzyDrinks",
    energy: 42,
    fat: 0,
    fatLabel: "0",
    saturates: 0,
    saturatesLabel: "0",
    sugar: 10.6,
    sugarLabel: "10.6",
    salt: 0,
    saltLabel: "0",
    fact: "The fizz in soft drinks is carbon dioxide gas bursting out of the liquid.",
    image: images.fizzyDrinks,
  },
  {
    label: "Spaghetti Bolognese",
    singular: true,
    key: "spagBol",
    energy: 129,
    fat: 4,
    fatLabel: "4",
    saturates: 1.6,
    saturatesLabel: "1.6",
    sugar: 2.2,
    sugarLabel: "2.2",
    salt: 0.3,
    saltLabel: "0.3",
    fact: "The original dish from Italy is called ‘Tagliatelle al ragu’ which is made from tagliatelle instead of spaghetti",
    image: images.spagBol,
  },
  {
    label: "Choc Spread on Toast",
    singular: true,
    key: "toastChocSpread",
    energy: 315,
    fat: 9.6,
    saturates: 3.1,
    sugar: 17.8,
    salt: 0.68,
    fatLabel: "9.6",
    saturatesLabel: "3.1",
    sugarLabel: "17.8",
    saltLabel: "0.68",
    fact: "The word toast comes from the Latin word ‘torrere’ 'to burn’.",
    image: images.toastChocSpread,
  },
  {
    label: "Tofu",
    singular: true,
    key: "tofu",
    energy: 123,
    fat: 6.9,
    saturates: 1,
    sugar: 0.7,
    salt: 0.1,
    fatLabel: "6.9",
    saturatesLabel: "1",
    sugarLabel: "0.7",
    saltLabel: "0.1",
    fact: "Tofu is a non-meat protein that was said to have first been made in China about 2,000 years ago by a chef who accidently over cooked soy milk causing it to form into lumps!",
    image: images.tofu,
  },
  {
    label: "Tuna in Spring Water",
    singular: true,
    key: "tunaWater",
    energy: 113,
    fat: 0.5,
    saturates: 0.2,
    sugar: 0,
    salt: 0.8,
    fatLabel: "0.5",
    saturatesLabel: "0.2",
    sugarLabel: "0",
    saltLabel: "0.8",
    fact: "Tuna are usually found in warmer oceans and can grown up to 700kg!",
    image: images.tunaWater,
  },
  {
    prefix: "a",
    label: "Vegetable Pizza",
    singular: true,
    key: "pizzaVeg",
    energy: 198,
    fat: 7.8,
    saturates: 2.6,
    sugar: 1.7,
    salt: 0.7,
    fatLabel: "7.8",
    saturatesLabel: "2.6",
    sugarLabel: "1.7",
    saltLabel: "0.7",
    fact: "Modern pizzas were invented in Italy in the 18th century!",
    image: images.pizzaVeg,
  },
  {
    label: "Water",
    singular: true,
    key: "water",
    energy: 0,
    fat: 0,
    saturates: 0,
    sugar: 0,
    salt: 0,
    fatLabel: "0",
    saturatesLabel: "0",
    sugarLabel: "0",
    saltLabel: "0",
    fact: "Your body is 70% water, so it’s essential you drink enough to keep your body working!",
    image: images.water,
  },
  {
    prefix: "an",
    label: "Egg & Cucumber Wrap",
    singular: true,
    key: "wrapEggCucumber",
    energy: 100,
    fat: 4.4,
    fatLabel: "4.4",
    saturates: 1.2,
    saturatesLabel: "1.2",
    sugar: 1.7,
    sugarLabel: "1.7",
    salt: 0.36,
    saltLabel: "0.36",
    fact: "Cucumbers are in fact a fruit because they grow from the flowers of a plant and hold the seeds!",
    image: images.wrapEggCucumber,
  },
  {
    prefix: "a",
    label: "Fruit Yogurt",
    singular: true,
    key: "yogFruit",
    energy: 91,
    fat: 2.9,
    saturates: 1.9,
    sugar: 12.2,
    salt: 0.12,
    fatLabel: "2.9",
    saturatesLabel: "1.9",
    sugarLabel: "12.2",
    saltLabel: "0.12",
    fact: "You can make your own fruit yogurt by mixing plain yogurt with chopped up pieces of your favourite fruit.",
    image: images.yogFruit,
  },
  {
    prefix: "a",
    label: "Plain Yogurt",
    singular: true,
    key: "yogPlain",
    energy: 82,
    fat: 4.5,
    saturates: 2.9,
    sugar: 5.6,
    salt: 0.13,
    fatLabel: "4.5",
    saturatesLabel: "2.9",
    sugarLabel: "5.6",
    saltLabel: "0.13",
    fact: "Yogurt is made from milk and good bacteria causing the milk to thicken up and tastes great with chopped fruit!",
    image: images.yogPlain,
  },
];
export default cards;
