import React from "react";
import styles from "./Footer.module.scss";
import LogoAOSugar from "../assets/img/ao-sugar.svg";
import LogoAOSalt from "../assets/img/ao-salt.svg";
import LogoQueenMary from "../assets/img/queen-mary.svg";

import LogoProper from "../assets/img/proper.svg";

const Footer = () => {
  return (
    <div className={styles.wrapper}>
      <footer>
        <div className={styles.logos}>
          <a href="http://www.actiononsugar.org/">
            <img className={styles.logo} src={LogoAOSugar} alt="" />
          </a>
          <a href="http://www.actiononsalt.org.uk/">
            <img className={styles.logo} src={LogoAOSalt} alt="" />
          </a>
          <a href="https://www.qmul.ac.uk/">
            <img className={styles.logo} src={LogoQueenMary} alt="" />
          </a>
          <a href="https://properdesign.co.uk">
            <img className={styles.logo} src={LogoProper} alt="" />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
