const games = [
  {
    name: "tl1",
    title: "Green Apple",
    description:
      "In this game, green beats amber, amber beats red and you can see the colours on the computer’s card.",
    rules: {
      compare: "colors",
      highLowOption: false,
    },
    cardOptions: {
      one: {
        player: true,
        dataHidden: true,
      },
      two: {
        dataHidden: true,
      },
    },
  },
  {
    name: "tl2",
    title: "Orange",
    description:
      "In this game green beats amber, amber beats red. But this time you can’t see the colours on the computer’s card.",
    rules: {
      compare: "colors",
      highLowOption: false,
    },
    cardOptions: {
      one: {
        player: true,
        dataHidden: true,
      },
      two: {
        colorsHidden: true,
        dataHidden: true,
      },
    },
  },
  {
    name: "tt1",
    title: "Strawberry",
    description:
      "In this game, the lowest numbers win. You can see the type of food on the computer’s card but not the colours or numbers.",
    rules: {
      compare: "numbers",
      highLowOption: false,
    },
    cardOptions: {
      one: {
        player: true,
      },
      two: {
        colorsHidden: true,
        dataHidden: true,
      },
    },
  },
  {
    name: "tt2",
    title: "Cherry",
    description:
      "In this game, the lowest numbers win and you can’t see the computer’s card at all!",
    rules: {
      compare: "numbers",
      highLowOption: false,
    },
    cardOptions: {
      one: {
        player: true,
      },
      two: {
        cardHidden: true,
      },
    },
  },
];

export default games;
