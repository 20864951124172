import React from "react";
import styles from "./TableCards.module.scss";
import cardBack from "../assets/img/card-back.png";

const TableCards = ({ stack }) => {
  return (
    <svg className={styles.stack} viewBox="-10 123 370 123">
      {stack.length > 0 && (
        <g>
          {stack.map((card, i) => (
            <g
              key={i}
              transform={`rotate(${Math.sin(i * 2)} 112.5 175) translate(${
                stack.length / 2 - Math.sin(i) * 4
              } ${stack.length - i + 2})`}
            >
              <rect
                height="225"
                width="350"
                fill="#fbb03f"
                rx="15"
                stroke="#bc842f"
                strokeWidth="1"
              />
              <image
                href={cardBack}
                width="215"
                height="340"
                fill="white"
                x="0"
                y="5"
                transform=" translate(350) rotate(90)"
              />
            </g>
          ))}
        </g>
      )}
    </svg>
  );
};

export default TableCards;
