const levelToLabel = (level) => {
  switch (level) {
    case "high":
      return "high";
    case "med":
      return "medium";
    default:
      return "low";
  }
};

export default levelToLabel;
