import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Client } from "boardgame.io/react";
import { Game } from "./Game";
import { Board } from "./Board";
import games from "../library/games";

const Play = () => {
  const { gameString } = useParams();

  const game = games.find((game) => gameString === game.name);

  const GameClient = Client({
    game: Game({
      rules: game.rules,
    }),
    board: Board,
    numPlayers: 1,
    debug: false,
  });

  return (
    <div>
      <Helmet>
        <title>Food Flips: {game.title}</title>
        <meta name="description" content={game.description} />
      </Helmet>
      <GameClient game={game} />
    </div>
  );
};

export default Play;
