import bakedBeans from "../characters/bakedBeans.png";
import cerealBar from "../characters/cerealBar.png";
import cerealChoc from "../characters/cerealChoc.png";
import cheeseBurger from "../characters/cheeseBurger.png";
import cheeseCheddar from "../characters/cheeseCheddar.png";
import cookieChocChip from "../characters/cookieChocChip.png";
import crispsReadySalted from "../characters/crispsReadySalted.png";
import fizzyDrinks from "../characters/fizzyDrinks.png";
import frenchFries from "../characters/frenchFries.png";
import hotChoc from "../characters/hotChoc.png";
import jellySweets from "../characters/jellySweets.png";
import juiceOrange from "../characters/juiceOrange.png";
import ketchup from "../characters/ketchup.png";
import marshmallow from "../characters/marshmallow.png";
import mayo from "../characters/mayo.png";
import milkSemi from "../characters/milkSemi.png";
import omlette from "../characters/omlette.png";
import pizzaPepperoni from "../characters/pizzaMeat.png";
import pizzaVeg from "../characters/pizzaVeg.png";
import porridge from "../characters/porridge.png";
import sandwichHamCheese from "../characters/sandwichHamCheese.png";
import spagBol from "../characters/spagBol.png";
import toastChocSpread from "../characters/toastChocSpread.png";
import toastEgg from "../characters/toastEgg.png";
import tofu from "../characters/tofu.png";
import tunaWater from "../characters/tunaWater.png";
import water from "../characters/water.png";
import wrapEggCucumber from "../characters/wrapEggCucumber.png";
import yogFruit from "../characters/yogFruit.png";
import yogPlain from "../characters/yogPlain.png";

const images = {
  bakedBeans: bakedBeans,
  cerealBar: cerealBar,
  cerealChoc: cerealChoc,
  cheeseBurger: cheeseBurger,
  cheeseCheddar: cheeseCheddar,
  cookieChocChip: cookieChocChip,
  crispsReadySalted: crispsReadySalted,
  fizzyDrinks: fizzyDrinks,
  frenchFries: frenchFries,
  hotChoc: hotChoc,
  jellySweets: jellySweets,
  juiceOrange: juiceOrange,
  ketchup: ketchup,
  marshmallow: marshmallow,
  mayo: mayo,
  milkSemi: milkSemi,
  omlette: omlette,
  pizzaPepperoni: pizzaPepperoni,
  pizzaVeg: pizzaVeg,
  porridge: porridge,
  sandwichHamCheese: sandwichHamCheese,
  spagBol: spagBol,
  toastChocSpread: toastChocSpread,
  toastEgg: toastEgg,
  tofu: tofu,
  tunaWater: tunaWater,
  water: water,
  wrapEggCucumber: wrapEggCucumber,
  yogFruit: yogFruit,
  yogPlain: yogPlain,
};

export default images;
