const nutrients = {
  energy: {
    index: 0,
    name: "energy",
    label: "energy",
    trafficLightLabel: "Energy",
    unit: "kCal",
  },
  fat: {
    index: 1,
    name: "fat",
    label: "fat",
    trafficLightLabel: "Fat",
    unit: "g",
  },
  saturates: {
    index: 2,
    name: "saturates",
    label: "saturated fat",
    trafficLightLabel: "Saturates",
    unit: "g",
  },
  sugar: {
    index: 3,
    name: "sugar",
    label: "sugar",
    trafficLightLabel: "Sugars",
    unit: "g",
  },
  salt: {
    index: 4,
    name: "salt",
    label: "salt",
    trafficLightLabel: "Salt",
    unit: "g",
  },
};
export default nutrients;
