import React from "react";
import TrafficLight from "./TrafficLight";
import nutrients from "../library/nutrients-object";

// tl is 57w

const TrafficLights = ({
  className,
  x = "10",
  y = "260",
  width = "205",
  height = "75",
  card,
  cardOptions = {
    dataHidden: false,
    colorsHidden: false,
  },
  moves,
}) => {
  return (
    <svg
      className={className}
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 285 104"
    >
      <g transform="translate(-108)">
        {Object.keys(nutrients).map((key, i) => {
          const nutrient = nutrients[key];
          return (
            <g key={key} transform={`translate(${i * 54})`}>
              <TrafficLight
                nutrient={nutrient}
                value={card[key]}
                displayValue={card[`${key}Label`]}
                dataHidden={cardOptions.dataHidden}
                colorsHidden={cardOptions.colorsHidden}
                action={
                  cardOptions && cardOptions.player
                    ? () => moves.selectNutrient(key)
                    : null
                }
              />
            </g>
          );
        })}
      </g>
    </svg>
  );
};

export default TrafficLights;
