import React from "react";
import games from "../library/games";
import styles from "./GamesNav.module.scss";
import GameTeaser from "./GameTeaser";

const GamesNav = () => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Choose a Food Flips game to play</h2>
      <ol className={styles.list}>
        {games.map((game) => (
          <li key={game.name}>
            <GameTeaser game={game} />
          </li>
        ))}
      </ol>
    </div>
  );
};

export default GamesNav;
